@media screen and (min-width: 250px) and (max-width: 299px){

    html{
        background-size: 80%;
    }


    .header{
        margin: 0px 5px 20px 5px
    }

    .logo-ag-photo{
        display: none;
    }

    .logo{
       
        width: 100px;
    }

    .logo-name{
        font-size: 13px;
        margin-left: 0;
    }

    .header-language-select{
        width: 100px;
    }

    .menu__box{
        width: 150px;
        height: 13%;
        padding: 60px 0;
    }

    .wrapper-card{
        margin-bottom: 50px;
    }

    .card{
        width: 245px;
        height: 410px;
        gap: 16px;
        justify-content: flex-start;
        background-color: #fff;
    }

    .card-name{
        margin-top: 5px;
    }
    
    .card-description{
        font-size: 14px;
        padding: 0 7px 0 7px;
    }

    .card-price{
        font-size: 25px;
    }

    .card-ul{
        padding: 5px 4px 5px 25px;
        width: 190px;
    }

    .card-ul-text{
        font-size: 14px;
    }

    .upper-text-description{
        margin: 0;
        padding: 10px 10px;
    }

    .upper-text-info-main{
        font-size: 18px;
        margin-bottom: 20px;
    }

    .upper-text-info{
        font-size: 15px;
        text-align: center;
        margin: 0 10px 20px 10px;
    }

    .text-viewer {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: flex-start;
    }

    .text-widget-main{
        font-size: 18px;
    }

    .text-widget-description {
        margin-left: 30px;
        line-height: normal;
    }

    .text-widget {
        width: 100%;
    }

    .text-widget-description{
        margin: 0;
    }
    
    .bottom-information-text{
        font-size: 16px;
        text-align: center;
        margin: 30px 10px 30px 10px;    
    }

    .footer{
        margin: 0 5px 10px 5px;
    }
    .logo-ag-photo-footer{
        display: none;
    }
    
    .footer-logo-name{
        font-size: 13px;
    }
    
    .year-of-creation{
        width: 83px;
    }

    .footer-creator{
        width: 83px;
    }

}

@media screen and (min-width: 300px) and (max-width: 375px){

    html{
        background-size: 80%;
    }


    .header{
        margin: 0px 5px 20px 5px
    }

    .logo{
        width: 100px;
    }

    .logo-ag-photo{
        display: none;
    }

    .logo-name{
        font-size: 13px;
    }

    .MuiButton-root{
        font-size: 0.7rem !important;
    }

    .header-language-select{
        width: 100px;
    }

    .menu__box{
        height: 20%;
        padding: 60px 0;
    }

    .wrapper-card{
        margin-bottom: 50px;
    }

    .card{
        width: 285px;
        height: 350px;
        gap: 15px;
        justify-content: flex-start;
        background-color: #fff;
    }

    .card-price{
        font-size: 25px;
    }

    .card-ul{
        padding: 5px 5px 5px 25px;
    }

    .card-ul-text{
        font-size: 13px;
    }

    .upper-text-description{
        margin: 0;
    }

    .upper-text-info-main{
        font-size: 18px;
        margin-bottom: 20px;
    }

    .upper-text-info{
        font-size: 15px;
        text-align: center;
        margin: 0 10px 20px 10px;
    }

    .text-viewer {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: flex-start;
    }

    .text-widget-main{
        font-size: 18px;
    }

    .text-widget {
        width: 100%;
    }

    .text-widget-description{
        margin: 0;
    }
    
    .bottom-information-text{
        font-size: 16px;
        text-align: center;
        margin: 30px 10px 30px 10px;    
    }

    .footer{
        margin: 0 5px 10px 5px;
    }
    
    .logo-ag-photo-footer{
        display: none;
    }

    .footer-logo-name{
        font-size: 13px;
    }
    
    .year-of-creation{
        width: 100px;
    }

    .footer-creator{
        width: 100px;
    }

}

@media screen and (min-width: 376px) and (max-width: 575px){
    .header{
        margin: 0 5px 20px 5px;
    }

    .logo{
        width: 150px;
    }

    .logo-name{
        font-size: 13px;
    }

    .header-language-select{
        width: 150px;
    }

    .menu__box{
        height: 13%;
        padding: 80px 0;
    }

    .card{
        width: 345px;
        background-color: #fff;
    }

    .card-ul-text{
        font-size: 14px;
    }

    .upper-text-info-main{
        font-size: 23px;
    }

    .text-viewer {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: flex-start;
    }

    .text-widget-main{
        font-size: 18px;
    }

    .text-widget {
        width: 100%;
    }

    .text-widget-description{
        margin: 0;
    }

    .footer{
        margin: 0 5px 10px 5px;
    }
    
    .logo-ag-photo-footer{
        display: none;
    }

    .footer-logo-name{
        font-size: 13px;
    }
    
    .year-of-creation{
        width: 100px;
    }

    .footer-creator{
        width: 100px;
    }
}

@media screen and (min-width: 576px) and (max-width: 767px){
    .text-viewer {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: flex-start;
    }

    .logo-name{
        width: 170px;
        font-size: 17px;
    }

    .menu__box{
        width: 300px;
        height: 16%;
    }

    .footer-logo-name{
        font-size: 17px;
    }

    .card{
        background-color: #fff;
    }

    .text-widget-main{
        font-size: 18px;
    }

    .text-widget {
        width: 100%;
    }

    .text-widget-description{
        margin: 0;
    }

    .footer{
        margin: 0 10px 10px 10px;
    }

    .year-of-creation{
        width: 193px;
    }

    .footer-creator{
        width: 193px;
    }

}

@media screen and (min-width: 768px) and (max-width: 1024px){
    .header{
        margin: 0 20px 30px 20px;
    }
    
    .logo-name{
        width: 200px;
    }

    .menu__box{
        height: 13%;
        padding: 60px 0;
    }

    .card{
        background-color: #fff;
    }

    .upper-text-info{
        font-size: 22px;
    }

    .btn-insta {
        width: 250px;
        height: 70px;
    }

    .btn-insta-text {
        font-size: 18px;
        padding: 25px 18px 25px 18px;
    }

    .text-viewer {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: flex-start;
    }

    .text-widget-main{
        font-size: 27px;
    }

    .text-widget-description{
        font-size: 21px;
        line-height: 34px;
    }

    .text-widget {
        width: 100%;
    }

    .text-widget-description{
        margin: 0;
    }

    .footer{
        margin: 0 10px 10px 10px;
    }

    .year-of-creation{
        font-size: 16px;
    }

    .footer-creator{
        font-size: 18px;
    }

}
