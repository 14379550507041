.header{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 60px;
  align-items: center;
  margin: 0px 10px 80px 10px;
  position: relative;
}

.logo{
    width: 300px;
}

.nav-link{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    text-decoration: none;
    color: #000;
}

.logo-ag-photo{
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 80px;
    cursor: pointer;
}

.logo-name{
    font-family: 'PT Serif', serif;
    font-size: 20px;
    font-weight: 400;
    color: #564734;
    margin-left: 10px;
}

.hamburger-menu{
  display: none;
}

#menu__toggle {
  opacity: 0;
}

.menu__btn {
  display: flex; 
  align-items: center;  
  position: absolute;
  top: 20px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 2;
}

.menu__btn-open{
  display: flex; 
  align-items: center;  
  position: fixed;
  top: 20px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 2;
}

.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color:#564734;
  transition-duration: .25s;
}

.menu__btn > span::before {
  content: '';
  top: -8px;
}
.menu__btn > span::after {
  content: '';
  top: 8px;
}

.menu__btn-open> span,
.menu__btn-open> span::before,
.menu__btn-open> span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #fff;
  transition-duration: .25s;
}

.menu__btn-open > span::before {
  content: '';
  top: -8px;
}
.menu__btn-open > span::after {
  content: '';
  top: 8px;
}

.header-modal{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.menu__box {
  display: block;
  position: fixed;
  visibility: hidden;
  top: -100%;
  width: 250px;
  height: 20%;
  margin: 0;
  padding: 60px 0 10px 0;
  list-style: none;
  text-align: center;
  background-color: #d6bea5;
  box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);
  transition-duration: .25s;
  border-radius: 10px;
  z-index: 1;
  
}

.menu__item {
  display: block;
  padding: 12px 24px;
  color: #fff;
  font-family: 'PT Serif', serif;
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
  transition-duration: .25s;
}
.menu__item:hover {
  background-color: #ed9bae;
}

#menu__toggle:checked ~ .menu__btn-open > span {
  transform: rotate(45deg);
}
#menu__toggle:checked ~ .menu__btn-open > span::before {
  top: 0;
  transform: rotate(0);
}
#menu__toggle:checked ~ .menu__btn-open > span::after {
  top: 0;
  transform: rotate(90deg);
}
#menu__toggle:checked ~ .header-modal .menu__box {
  visibility: visible;
  top: 0;
}

.header-language-select{
  width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.MuiButton-text {
  background-color: #ed9bae !important;
}

.MuiButton-label {
  color: #564734 !important;
}

.MuiListItem-button {
  color: #564734 !important;
}

.MuiListItem-button:hover {
  text-decoration: none;
  background-color: #ed9bae !important;
}

.rotate {
  transform: rotate(180deg);
}

